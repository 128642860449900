import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/layout/Layout'
import SEO from '../components/seo'

const NotFoundPage = ({ data }) => (
  <Layout navbarData={data.contentfulHomePage.navbar}>
    <SEO title="404: Not found" />
    <h1>NOT FOUND</h1>
    <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
  </Layout>
)

export const query = graphql`
  query NotFoundPageQuery {
    contentfulHomePage {
      navbar {
        ...NavbarFragment
      }
    }
  }
`

export default NotFoundPage